import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { useSelector, useDispatch } from "react-redux";
// import Feedback from "../feedback";
import Feedback from "../dynamic-components/feedback";
import { resetFeedback } from "../../store/actions/feedback";
import { ThemeContext } from "../themes/theme-context";

const Container = tw.div`w-screen h-screen fixed top-0 left-0 z-20`;

const InnerContainer = tw.div`w-full h-full max-w-screen-xl mx-auto relative`;

const StyledPanel = tw.div`absolute top-0 left-0 w-screen h-screen flex flex-col items-center justify-center backdrop-blur-5 lg:backdrop-none lg:w-auto lg:h-auto lg:top-auto lg:bottom-32 lg:left-auto lg:right-10`;

const StyledPanelBg = styled.div(({ backgroundColor }) => [
	tw`absolute top-0 left-0 w-screen h-screen opacity-20 lg:hidden`,
	backgroundColor,
]);

const StyledInner = tw.div`rounded-3xl bg-white flex flex-col items-center justify-center shadow-resource-list-card w-80 py-12`;

const styledQuestion = tw`font-serif text-base leading-serif-base mb-6 text-center lg:text-lg lg:leading-serif-lg`;

const styledRating = tw`w-full px-12`;

const styledRatingScale = tw`flex flex-row items-center justify-center space-x-2 py-2.5`;

const styledComment = tw`w-full px-12`;

const styledLabel = tw`font-serif text-center text-base leading-serif-base mb-6 lg:text-lg lg:leading-serif-lg`;

const styledForm = tw`text-center lg:space-x-4`;

const styledInput = tw`border border-purple-light rounded-lg py-1.5 px-2 w-full mb-2`;

const styledButtons = tw`space-x-2`;

const generalFeedbackPath = "post-general-feedback";

const GeneralFeedback = ({ data }) => {
	const isShow = useSelector((state) => state.feedback.show === "general");

	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(resetFeedback("general"));
	};

	const { theme } = useContext(ThemeContext);

	return (
		isShow && (
			<Container>
				<InnerContainer onMouseUp={handleClose}>
					<StyledPanelBg backgroundColor={theme.bgColor} />
					<StyledPanel>
						<StyledInner onMouseUp={(e) => e.stopPropagation()}>
							<Feedback
								data={data}
								resourceId="general"
								ratingStyles={{
									styledQuestion,
									styledRating,
									styledRatingScale,
								}}
								commentStyles={{
									styledComment,
									styledLabel,
									styledForm,
									styledInput,
									styledButtons,
								}}
								path={generalFeedbackPath}
							/>
						</StyledInner>
					</StyledPanel>
				</InnerContainer>
			</Container>
		)
	);
};

export default GeneralFeedback;
