import loadable from "@loadable/component";
import React from "react";

const FeedbackProxy = loadable(() => import("../feedback"));

const Feedback = ({ data, resourceId, ratingStyles, commentStyles, path }) => {
	return (
		<FeedbackProxy
			data={data}
			resourceId={resourceId}
			ratingStyles={ratingStyles}
			commentStyles={commentStyles}
			path={path}
		/>
	);
};

export default Feedback;
